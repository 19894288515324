<template>
  <div class="grid">
      <!-- <div class="col-12 md:col-12 xl:col-10"> -->
      <div class="col-12 xl:col-7">  
				<Menu ref="menu" :model="overlayMenuItems" :popup="true" />
				<Button :label="getCountryLabel()" icon="pi pi-angle-down" @click="onToggleMenuClick" style="width: auto"/>
				<TabView lazy class="tabview-custom" @tab-click="onPeriodTabClick($event)">
          <template v-for="period in periods" :key="period.code">
            <TabPanel>
              <template #header>
                <span>{{period.name}}</span>
              </template>
              WwindexchartController {{perf}}
              <div class="card chart1-container">
                <div class="overview-chart">
                  <Chart ref="wwindexchart" type="line" :data="chartBase" :options="chartOptions"></Chart>
                </div>                
                <div id="chart1-tabs-container" class="chart1-tabs">
                  <template v-for="(wwindex, index) in wwindexData" :key="index">
                    <div class="chart1-tab" :class="getTabClass(index)" @click="onInfoCardClick($event)" :data-index=index>                    
                      <h4>{{wwindex.name}}</h4>
                      <h5>{{wwindex.close_last}}</h5>
                      <span :class="getPriceClass(wwindex.diff_last, index)">{{wwindex.diff_last}} ({{wwindex.ratio_last}}%)</span>
                      <h6>平均値: {{wwindex.close_average}}</h6>
                      <h6>乖離率: {{wwindex.close_deviation}}%</h6>
                      <h6>{{currentDate}} {{wwindex.timestamp}}</h6>
                    </div>
                  </template>
                </div>             
              </div>
            </TabPanel>
          </template>
				</TabView>
      </div>
  </div>
</template>

<script>
import WwindexchartService from '@/service/WwindexchartService';
// import { Chart } from 'chart.js';
// import annotationPlugin from 'chartjs-plugin-annotation';
import CommonService from '../service/CommonService';

export default {
  data() {
    return {
      perf: null,
      currentDate: null,
      currentPeriodID: 0,
      currentWwindexID: 0,
      selectedWwindexID: 0,
      wwindexTitlesDefault: ['日経平均', 'NYダウ'],
      wwindexTitle: '日経平均',
      currentCountryID: 0,
      countryNames: ['国内', '海外'],
      periods: [
        {name: '25日', code: '25d'},        
        {name: '5日', code: '5d'},
        {name: '10日', code: '10d'},
        {name: '50日', code: '50d'},
        {name: '75日', code: '75d'},
        {name: '6ヶ月', code: '6m'},
        {name: '1年', code: '1y'},
      ],
      wwindexData: [],
      chartOptions: null,
      chartBase: {
        labels: [],
        datasets: [ 
          {
            type: 'line',
            label: '終値',
            data: [],
            borderColor: ['rgba(0, 0, 0, .6)'],
            backgroundColor: ['rgba(47, 142, 229, 0.05)'],
            borderWidth: 1.5,
            fill: true,
            pointRadius: 3,
            tension: .4,
            pointBorderWidth: .7,
            pointBackgroundColor: ['rgba(255, 255, 255, 1)'],
            pointHoverBackgroundColor: ['#000000']
          },           
          {
            type: 'line',
            label: '5日',
            data: [],
            borderColor: ['rgba(255, 0, 255, 1)'],
            backgroundColor: ['rgba(255, 0, 255, 0.4)'],
            borderWidth: .5,
            fill: false,
            pointRadius: 0,
            tension: .4,
            pointBackgroundColor: ['rgba(255, 0, 255, 0.2)'],
          },
          {
            type: 'line',
            label: '25日',
            data: [],
            borderColor: ['rgba(0, 150, 0, 1)'],
            backgroundColor: ['rgba(0, 150, 0, .4)'],
            borderWidth: .5,
            fill: false,
            pointRadius: 0,
            tension: .4,
            pointBackgroundColor: ['rgba(0, 150, 0, 0.2)'],
          },
          {
            type: 'line',
            label: '75日',
            data: [],
            borderColor: ['rgba(47, 142, 229, 1)'],
            backgroundColor: ['rgba(47, 142, 229, .5)'],
            borderWidth: .5,
            fill: false,
            pointRadius: 0,
            tension: .4,
            pointBackgroundColor: ['rgba(47, 142, 229, 0.2)'],
          },
          {
            type: 'line',
            yAxisID: 'y2',
            label: '出来高',
            data: [],
            borderColor: ['rgba(0, 0, 0, .7)'],
            backgroundColor: ['rgba(0, 0, 0, .2)'],
            borderWidth: .5,
            fill: false,
            pointRadius: 0,
            tension: .4,
            pointBorderWidth: .7,
            pointBackgroundColor: ['rgba(0, 0, 0, .2)'],
          },
        ]
      },
      overlayMenuItems: [
        {
          label: '国内',
          icon: 'pi pi-circle',
					command: () => {
            if(this.currentCountryID !== 0){
              this.reloadChart(0);
            }
					}               
        },     
        {
          label: '海外',
          icon: 'pi pi-globe',
					command: () => {
            if(this.currentCountryID !== 1){
              this.reloadChart(1);
            }
					}           
        }
      ]
    }
  },

  wwindexchartService: null,
  common: null,
  created() {
      this.wwindexchartService = new WwindexchartService();
      this.common = new CommonService();
      // Chart.register(annotationPlugin);
  },

  mounted() {
    this.getChartData(0, 0);
    this.applyChartOptions();
  },

  methods: {
    reloadChart(countryID) {
      this.currentWwindexID = 0;
      this.selectedWwindexID = 0;
      this.wwindexTitle = this.wwindexTitlesDefault[countryID];              
      this.resetChart();
      this.getChartData(this.currentPeriodID, countryID);
      this.applyChartOptions();
    },
    getCountryLabel() {
      return this.countryNames[this.currentCountryID];
    },
    resetChart() {
      this.chartBase.labels = [];
      this.wwindexData = []; 
    },
    getChartData(tabIndex, countryID) {
      this.wwindexchartService.getAll(this.periods[tabIndex].code, countryID)
        .then(d => {

          for (let i=0; i<d.data.label_cnt; i++) {
            this.chartBase.labels.push(d.data.labels[i]);
            if(i == d.data.label_cnt-1){
              this.currentDate = d.data.labels[i];
            }
          }
          for (let i=0; i<d.data.dataset_cnt; i++) {
            let sum = 0;
            this.wwindexData.push( { 
                name: d.data.datasets[i].index_name,
                code: d.data.datasets[i].index_cd,
                timestamp: d.data.datasets[i].price_timestamp,
                close_last: this.common.round_2(d.data.datasets[i].close[d.data.label_cnt-1]),
                diff_last: d.data.datasets[i].ago_today_diff[d.data.label_cnt-1],
                ratio_last: this.common.round_2(d.data.datasets[i].ago_today_ratio[d.data.label_cnt-1]),
                close: [],
                ma5: [],
                ma25: [],
                ma75: [],
                volume: []
            } );
            for (let j=0; j<d.data.label_cnt; j++) {
              this.wwindexData[i].close.push(d.data.datasets[i].close[j]);
              this.wwindexData[i].ma5.push(d.data.datasets[i].ma5[j]);
              this.wwindexData[i].ma25.push(d.data.datasets[i].ma25[j]);
              this.wwindexData[i].ma75.push(d.data.datasets[i].ma75[j]);
              this.wwindexData[i].volume.push(d.data.datasets[i].volume[j]);
              sum += d.data.datasets[i].close[j];
            }
            this.wwindexData[i].close_average = this.common.round_2(sum / d.data.label_cnt);
            let avg = sum / d.data.label_cnt;
            let dr = ((d.data.datasets[i].close[d.data.label_cnt-1] - avg)/Math.abs(avg))*100;
            this.wwindexData[i].close_deviation = this.common.round_2(dr);
          }
          this.chartBase.datasets[0].data = this.wwindexData[this.selectedWwindexID].close;
          this.chartBase.datasets[1].data = this.wwindexData[this.selectedWwindexID].ma5;
          this.chartBase.datasets[2].data = this.wwindexData[this.selectedWwindexID].ma25;    
          this.chartBase.datasets[3].data = this.wwindexData[this.selectedWwindexID].ma75;
          this.chartBase.datasets[4].data = this.wwindexData[this.selectedWwindexID].volume;
          this.perf = d.perf;
        }
      );
      this.currentPeriodID = tabIndex;
      this.currentCountryID = countryID;
    },
    getTabClass(idx){
      return [
        {'chart1-tab-active':this.selectedWwindexID===idx},
      ]
    },    
    getPriceClass(diff, idx) {
      return [
        {
          'positive-chart': diff > 0 && this.selectedWwindexID!==idx,
          'negative-chart': diff < 0 && this.selectedWwindexID!==idx,
          'neutral-chart': diff == 0 && this.selectedWwindexID!==idx,
          'positive-chart-active': diff > 0 && this.selectedWwindexID===idx,
          'negative-chart-active': diff < 0 && this.selectedWwindexID===idx,
          'neutral-chart-active': diff == 0 && this.selectedWwindexID===idx,
        }
      ];
    },  
    applyChartOptions() {
      this.chartOptions = {
        plugins: {
          title: {
            display: true,
            text: this.wwindexTitle + ' (' + this.periods[this.currentPeriodID].name + ')',
            font: {
              size: 14,
            },
            color: 'rgba(0, 0, 0, .5)',
          },
          legend: {
            display: true,
            labels: {
              color: '#A0A7B5'
            },
            position: 'right',
            align: 'start',
          },
          // annotation: {
          //   annotations: {
          //     line1: {
          //       type: 'line',
          //       yScaleID: 'y',
          //       yMin: 28500,
          //       yMax: 28500,          
          //       borderColor: 'rgb(255, 0, 0, .7)',
          //       borderWidth: .6,
          //     }
          //   },
          // },          
        },
        responsive: true,
        hover: {mode: 'index'},
        scales: {
          y: {
            ticks: {color: '#A0A7B5'},
            grid: {color:  'rgba(160, 167, 181, .3)'}
          },
          x: {
            ticks: {color: '#A0A7B5'},
            grid: {color:  'rgba(160, 167, 181, .3)'}
          },
          y2: {
            position: 'right',
            ticks: {color: '#A0A7B5'},
            grid: {
              grid: {color: 'rgba(160, 167, 181, .3)'},
              borderDash: [2, 3]
            }
          },           
        }
      }
    },
    onToggleMenuClick(event) {
      this.$refs.menu.toggle(event);
    },      
    onPeriodTabClick(event){
      if (this.currentPeriodID != event.index) {
        this.currentPeriodID = event.index;
        this.resetChart();
        this.getChartData(event.index, this.currentCountryID);
        this.applyChartOptions();
      }
    },  
    onInfoCardClick(event) {
      this.selectedWwindexID = parseInt(event.currentTarget.getAttribute('data-index'));
      if(this.selectedWwindexID !== this.currentWwindexID){
        this.currentWwindexID = this.selectedWwindexID;
        this.wwindexTitle = this.wwindexData[this.selectedWwindexID].name;
        this.chartBase.datasets[0].data = this.wwindexData[this.selectedWwindexID].close;
        this.chartBase.datasets[1].data = this.wwindexData[this.selectedWwindexID].ma5;
        this.chartBase.datasets[2].data = this.wwindexData[this.selectedWwindexID].ma25;
        this.chartBase.datasets[3].data = this.wwindexData[this.selectedWwindexID].ma75;
        this.chartBase.datasets[4].data = this.wwindexData[this.selectedWwindexID].volume;
        this.applyChartOptions();
      }
    }  
  }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/charts.scss';
@import '../assets/prod/styles/prices.scss';
</style>